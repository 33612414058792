// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-busca-tsx": () => import("./../src/pages/busca.tsx" /* webpackChunkName: "component---src-pages-busca-tsx" */),
  "component---src-pages-cadastro-tsx": () => import("./../src/pages/cadastro.tsx" /* webpackChunkName: "component---src-pages-cadastro-tsx" */),
  "component---src-pages-checkout-tsx": () => import("./../src/pages/checkout.tsx" /* webpackChunkName: "component---src-pages-checkout-tsx" */),
  "component---src-pages-fracasso-tsx": () => import("./../src/pages/fracasso.tsx" /* webpackChunkName: "component---src-pages-fracasso-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-minha-conta-tsx": () => import("./../src/pages/minha-conta.tsx" /* webpackChunkName: "component---src-pages-minha-conta-tsx" */),
  "component---src-pages-sucesso-tsx": () => import("./../src/pages/sucesso.tsx" /* webpackChunkName: "component---src-pages-sucesso-tsx" */),
  "component---src-templates-category-template-products-tsx": () => import("./../src/templates/categoryTemplateProducts.tsx" /* webpackChunkName: "component---src-templates-category-template-products-tsx" */),
  "component---src-templates-child-product-template-tsx": () => import("./../src/templates/childProductTemplate.tsx" /* webpackChunkName: "component---src-templates-child-product-template-tsx" */),
  "component---src-templates-simple-product-template-tsx": () => import("./../src/templates/simpleProductTemplate.tsx" /* webpackChunkName: "component---src-templates-simple-product-template-tsx" */)
}

