import * as actionTypes from "./actions/actionTypes"

const initialState = {
  cart: [],
  userAuth: {
    auth: false,
    token: "",
  },
  userData: {},
  quoteId: null,
  isLoadingCheckout: true,
  isLoading: false,
  totalPrice: 0,
  billetUrl: "",
  swatchFilter: [],
  wishlist: [],
  drawers: {
    isCartOpen: true,
    isMeasuringTechniquesOpen: true,
    isDrawerLeftOpen: false,
    isDrawerRightOpen: false,
  },
  modals: {
    isLoginOpen: false
  },
  customOptionOpen: false,
  isAlertOpen: false,
  alertMessage: null,
  alertButton: null,
  customOptionsAlert: false,
  loginAlert: false,
  successOrderNumber: 0,
  myAccount: {
    openChangePassword: false,
    openEditAddress: false, 
    openOrder: false,
    savingIn: '',
    selectedOrder: null
  }
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TO_CART: {
      const newCart = [...state.cart]
      const newTotalPrice =
        state.totalPrice +
        action.payload.visibleCartItem.price *
          +action.payload.visibleCartItem.qty

      const index = newCart.findIndex(
        x =>
          x.visibleCartItem.id === action.payload.visibleCartItem.id &&
          JSON.stringify(x.visibleCartItem.custom_options) ===
            JSON.stringify(action.payload.visibleCartItem.custom_options)
      )
      if (index === -1) {
        newCart.push({
          visibleCartItem: action.payload.visibleCartItem,
          magentoCartItem: action.payload.magentoCartItem,
        })
      } else {
        newCart[index].visibleCartItem.qty++
        newCart[index].magentoCartItem.cartItem.qty++
      }

      return {
        ...state,
        cart: newCart,
        cartOpen: true,
        totalPrice: newTotalPrice,
      }
      //return state;
    }
    case actionTypes.INCREMENT_PRODUCT_QTY: {
      const newCart = [...state.cart]

      newCart[action.payload.idx].magentoCartItem.cartItem.qty++
      newCart[action.payload.idx].visibleCartItem.qty++

      const newTotalPrice =
        state.totalPrice + newCart[action.payload.idx].visibleCartItem.price
      return {
        ...state,
        cart: newCart,
        totalPrice: newTotalPrice,
      }
    }
    case actionTypes.DECREMENT_PRODUCT_QTY: {
      const newCart = [...state.cart]

      newCart[action.payload.idx].magentoCartItem.cartItem.qty--
      newCart[action.payload.idx].visibleCartItem.qty--
      const newTotalPrice =
        state.totalPrice - newCart[action.payload.idx].visibleCartItem.price
      return {
        ...state,
        cart: newCart,
        totalPrice: newTotalPrice,
      }
    }
    case actionTypes.DELETE_ITEM_FROM_CART: {
      const newCart = [...state.cart]
      const newTotalPrice =
        state.totalPrice -
        newCart[action.payload.idx].visibleCartItem.price *
          +newCart[action.payload.idx].visibleCartItem.qty
      newCart.splice(action.payload.idx, 1)
      // console.log(newCart)
      return {
        ...state,
        cart: newCart,
        totalPrice: newTotalPrice,
      }
    }
    case actionTypes.TOGGLE_DRAWER_CART: {
      const copyDrawers = {...state.drawers, isCartOpen: !state.drawers.isCartOpen}

      return {
        ...state,
        drawers: copyDrawers,
      }
    }
    case actionTypes.TOGGLE_DRAWER_MEASURING_TECHNIQUES: {
      const copyDrawers = {...state.drawers, isMeasuringTechniquesOpen: !state.drawers.isMeasuringTechniquesOpen}

      return {
        ...state,
        drawers: copyDrawers,
      }
    }
    case actionTypes.TOGGLE_DRAWER_RIGHT: {
      let copyDrawers;

      if(state.drawers.isCartOpen){
        copyDrawers = {...state.drawers, isDrawerRightOpen: !state.drawers.isDrawerRightOpen}
      }
      
      return {
        ...state,
        drawers: copyDrawers,
      }
    }  
    case actionTypes.TOGGLE_DRAWER_LEFT: {
      // console.log('ok')
      let copyDrawers;
      
      if(state.drawers.isMeasuringTechniquesOpen){
        copyDrawers = {...state.drawers, isDrawerLeftOpen: !state.drawers.isDrawerLeftOpen}
      }
      return {
        ...state,
        drawers: copyDrawers,
      }
    }      
    case actionTypes.LOGOUT: {
      return {
        ...state,
        userAuth:{
          auth: false,
          token: "",
        },
        cart:[],
        wishlist:[],
        userData:{}
      }
    }
    case actionTypes.LOGIN_USER_DRAWER: {
      const copyDrawers = {...state.drawers};
      const copyModals = {...state.modals};
      copyModals.isLoginOpen = false;
      // console.log(action.payload.userData)
      return {
        ...state,
        userAuth: action.payload.userAuth,
        wishlist: action.payload.wishlist,
        cart: action.payload.cart,
        quoteId: action.payload.quoteId,
        totalPrice: action.payload.totalPrice,
        userData: action.payload.userData,
        drawers: copyDrawers,
        modals: copyModals,
        isLoading: false
      }
    }
    case actionTypes.SET_LOADING_TRUE:{
      return {
        ...state,
        isLoading:true
      }
    }
    case actionTypes.SET_BILLET_URL: {
      return {
        ...state,
        billetUrl: action.payload.billet,
      }
    }
    case actionTypes.CHANGE_CUSTOMER_DATA: {
      return {
        ...state,
        userData: action.payload.userData,
      }
    }
    case actionTypes.TOGGLE_CHANGE_PASSWORD: {
      
      const updatedMyAccount = {...state.myAccount}
      updatedMyAccount.openChangePassword = action.payload.changePasswordOpen;
      
      return {
        ...state,
        myAccount: updatedMyAccount
      }
    }
    case actionTypes.TOGGLE_EDIT_ADDRESS: {

      const updatedMyAccount = {...state.myAccount}
      updatedMyAccount.openEditAddress = action.payload.editAddressOpen;
      
      return {
        ...state,
        myAccount: updatedMyAccount
      }
    }
    case actionTypes.ADD_TO_WISHLIST: {
      const updatedWishlist = [...state.wishlist]
      const id = action.payload
      updatedWishlist.push(id)
      console.log(updatedWishlist)
      return {
        ...state,
        wishlist: updatedWishlist,
      }
    }
    case actionTypes.REMOVE_FROM_WISHLIST: {
      const updatedWishlist = [...state.wishlist]
      const id = action.payload
      const idx = updatedWishlist.indexOf(id)
      updatedWishlist.splice(idx, 1)
      console.log(updatedWishlist)
      return {
        ...state,
        wishlist: updatedWishlist,
      }
    }
    case actionTypes.OPEN_MODAL_LOGIN_USER: {
      const copyModals = {...state.modals};
      copyModals.isLoginOpen = true;
      return {
        ...state,
        modals: copyModals,
        isLoading: false
      }
    }
    case actionTypes.CLOSE_MODAL_LOGIN_USER: {
      const copyModals = {...state.modals};
      copyModals.isLoginOpen = false;
      return {
        ...state,
        modals: copyModals
      }
    }
    case actionTypes.SET_LOADING_FALSE: {
      return {
        ...state,
        isLoading:false
      }
    }
    case actionTypes.SAVE_USER_DATA: {
      console.log(action.payload.userData)
      return {
        ...state,
        userData:{...action.payload.userData},
        isLoading:false
      }
    }
    case actionTypes.CREATE_NEW_CART: {
      return {
        ...state,
        cart: [],
        quoteId: action.payload.quoteId,
        successOrderNumber: action.payload.successOrderNumber,
        isLoading: false,
        totalPrice: 0,
      }
    }
    case actionTypes.EDIT_ADDRESS_REFERENCE: {
      const copyMyAccount = {...state.myAccount};
      copyMyAccount.savingIn = action.payload.savingIn;
      
      return {
        ...state,
        myAccount: copyMyAccount
      }
    }
    case actionTypes.SET_SELECTED_ORDER: {
      const copyMyAccount = {...state.myAccount};
      copyMyAccount.selectedOrder = action.payload.selectedOrder;
      
      return {
        ...state,
        myAccount: copyMyAccount
      }
    }
    case actionTypes.CLOSE_ALERT: {
      return {
        ...state,
        isAlertOpen: false,
        alertMessage: null,
        alertButton: null
      }
    }
    case actionTypes.GLOBAL_ALERT: {
      return {
        ...state,
        isAlertOpen:true,
        alertMessage:action.payload.message,
        alertButton: action.payload.button
      }
    }
    default:
      return state
  }
}

export default reducer
